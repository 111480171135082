<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Cards Layout</title>
  </head>
  <body>
    <div class="container">
      <!-- Banner -->
      <div class="card-banner">
        <div class="overlay">
          <img
            src="/assets/svg/dashboard/bienvenido.svg"
            alt="Bienvenido Administrador"
            class="welcome-text"
          />
        </div>
        <img src="/assets/svg/dashboard/dashboard.svg" alt="Banner" />
      </div>

      <!-- Segunda fila -->
      <div class="row">
        <div class="card wide-card">
          <div class="avatar-section">
            <img
              [src]="
                employeeData?.imageEmployee ||
                './assets/images/image-profile.png'
              "
              alt="profile"
              class="avatar"
            />
            <button
              pButton
              type="button"
              icon="pi pi-pencil"
              class="p-button-rounded edit-button"
            ></button>
          </div>
          <div class="info-section">
            <h2 class="name">
              {{ employeeData?.names | capitalize }}
              {{ employeeData?.lastNames | capitalize }}
            </h2>
            <div class="detail">
              <span class="label">Rol:</span>
              <span class="value">{{
                employeeData?.jobPositionName || "Administrador" | capitalize
              }}</span>
            </div>
            <div class="detail">
              <span class="label">Email:</span>
              <span class="value">{{ employeeData?.email }}</span>
            </div>
            <div class="detail">
              <span class="label">Número:</span>
              <span class="value">{{ employeeData?.phone }}</span>
            </div>
            <div class="detail">
              <span class="label">Empresa:</span>
              <span class="value">{{ employeeData?.companyName }}</span>
            </div>
          </div>
        </div>

        <div class="card small-card">
          <div class="calendar-container">
            <p-calendar
              [(ngModel)]="selectedDate"
              [readonlyInput]="true"
              inline="true"
              [panelStyleClass]="'birthday-calendar'"
              (onMonthChange)="onMonthChange($event)"
            >
              <ng-template pTemplate="date" let-date>
                <span [ngClass]="{ 'birthday-highlight': isBirthday(date) }">
                  {{ date.day }}
                </span>
              </ng-template>
            </p-calendar>
          </div>
        </div>
        <div class="card small-card"></div>
      </div>

      <!-- Tercera fila -->
      <div class="row">
        <div class="card half-card">
          <h2 class="section-title">Info. Básica</h2>
          <div class="info-basic">
            <div class="info-item">
              <span class="label">Inicio</span>
              <span class="value">{{ employeeData?.startDate }}</span>
            </div>
            <div class="info-item">
              <span class="label">Hasta</span>
              <span class="value">{{ employeeData?.endDate }}</span>
            </div>
            <div class="info-item">
              <span class="label">Modalidad</span>
              <span class="value">{{
                employeeData?.workingModeName | capitalize
              }}</span>
            </div>
            <div class="info-item">
              <span class="label">Planilla</span>
              <span class="value">{{
                employeeData?.payRoll == true
                  ? "Estás en Planilla"
                  : "Estás en RxH"
              }}</span>
            </div>
          </div>

          <hr />
          <h2 class="section-title">Info. Personal</h2>
          <div class="info-personal">
            <div class="info-row">
              <span class="label">Nacimiento</span>
              <span class="value"
                ><i class="pi pi-calendar"></i>
                {{ employeeData?.birthDate }}</span
              >
            </div>
            <div class="info-row">
              <span class="label">Dirección</span>
              <span class="value"
                ><i class="pi pi-map-marker"></i>
                {{ employeeData?.address | capitalize }}</span
              >
            </div>
            <div class="info-row">
              <span class="label">Correo</span>
              <span class="value">{{ employeeData?.email }}</span>
            </div>
          </div>

          <hr />
          <h2 class="section-title">Ocupación</h2>
          <div class="occupation">
            <div class="occupation-item">
              <i class="pi pi-clock"></i>
              <div class="occupation-item-bottom">
                <span class="value">Tiempo Completo</span>
                <span class="subvalue">9am-7pm</span>
              </div>
            </div>
            <div class="occupation-item">
              <i class="pi pi-microsoft"></i>
              <div class="occupation-item-bottom">
                <span class="value"
                  >Área de {{ employeeData?.areaName | capitalize }}</span
                >
                <span class="subvalue">{{ employeeData?.companyName }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="card half-card-b">
          <h3>Cumpleaños de {{ getMonthName() }}</h3>
          <div class="birthday-list">
            <!-- Spinner mientras carga -->
            <div *ngIf="isLoadingBirthdays" class="spinner-container">
              <p-progressSpinner
                [style]="{ width: '1.5rem', height: '1.5rem' }"
                animationDuration="1.5s"
                aria-label="Cargando"
              ></p-progressSpinner>
            </div>

            <!-- Contenido cuando ya cargó -->
            <ng-container *ngIf="!isLoadingBirthdays">
              <div
                *ngFor="let birthday of getCurrentMonthBirthdays()"
                class="birthday-item"
              >
                <div class="employee-name">
                  {{ birthday.employeeName | capitalize }}
                </div>
                <div class="company-name">
                  {{ birthday.companyName  | capitalize}}
                </div>
                <div class="birth-date">
                  {{ formatBirthDate(birthday.birthDate) }}
                </div>
              </div>
              <div
                *ngIf="getCurrentMonthBirthdays().length === 0"
                class="no-birthdays"
              >
                No hay cumpleaños este mes
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
