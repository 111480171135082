import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../services/employee.service';
import { take } from 'rxjs';

interface EmployeeBirthday {
  employeeDocumentNumber: string;
  employeeName: string;
  companyName: string;
  birthDate: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  userData: any = null;
  employeeData: any;
  selectedDate: Date | null = null;
  birthdays: EmployeeBirthday[] = [];
  currentViewMonth: number = new Date().getMonth() + 1;
  isLoadingBirthdays: boolean = false;

  coupons = [
    {
      title: 'Descanso por maternidad',
      expiration: new Date('2024-07-26T00:00:00'),
    },
    {
      title: 'Descanso por maternidad',
      expiration: new Date('2024-07-26T00:00:00'),
    },
    {
      title: 'Chancho al palo con papas',
      expiration: new Date('2024-07-26T00:00:00'),
    },
  ];

  constructor(private employeeService: EmployeeService) {}

  ngOnInit(): void {
    this.employeeService.currentUser$.subscribe((data) => {
      this.employeeData = data;
    });
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }

    this.loadBirthdays();
  }

  loadBirthdays(): void {
    this.isLoadingBirthdays = true;
    this.employeeService.getAllBirthday().subscribe({
      next: (data: EmployeeBirthday[]) => {
        this.birthdays = data;
        this.isLoadingBirthdays = false;
      },
      error: (err) => {
        console.error('Error al obtener cumpleaños:', err);
        this.isLoadingBirthdays = false;
      },
    });
  }

  isBirthday(calendarDate: any): boolean {
    const result = this.birthdays?.some((employee) => {
      const [day, month] = employee.birthDate.split('/').map(Number);

      return day === calendarDate.day && month === calendarDate.month + 1;
    });

    return result;
  }

  isLast(coupon: any): boolean {
    return this.coupons.indexOf(coupon) === this.coupons.length - 1;
  }

  getMonthName(): string {
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return months[this.currentViewMonth - 1];
  }

  onMonthChange(event: any): void {
    this.currentViewMonth = event.month;
  }

  getCurrentMonthBirthdays(): EmployeeBirthday[] {
    if (!this.birthdays || this.birthdays.length === 0) return [];

    return this.birthdays
      .filter((birthday) => {
        const [day, month] = birthday.birthDate.split('/').map(Number);
        return month === this.currentViewMonth;
      })
      .sort((a, b) => {
        const [dayA] = a.birthDate.split('/').map(Number);
        const [dayB] = b.birthDate.split('/').map(Number);
        return dayA - dayB;
      });
  }

  formatBirthDate(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return `${day} de ${months[parseInt(month) - 1]}`;
  }
}
